
function Section() {
  return (
    <div className="section section-reverse" id="s4">
      <div className="bg-wrap">
        <div className="pic f1 bg" id="p13" />
      </div>

      <div className="text">
        <div className="text-border">
          <h1>KAMERALNY DOM</h1>
          <h2>w centrum metropolii</h2>
          <div className="running-text">
            Ideą projektu było stworzenie unikatowego w skali Dzielnicy oraz miasta miejsc do mieszkania. Budynek z założenia miał prowadzić dialog z sąsiadującą zielenią Parku. Założeniem było takie kształtowanie przestrzeni mieszkalnych by wypełnione były widokiem zieleni. Postawiono na budynek z jednej strony domknięty by chronić prywatność a z drugiej maksymalnie otwarty na całą szerokość pomieszczeń panoramicznymi oknami.
            <br /><br />
            Uzyskane wrażenie to mieszkanie z widokiem na własny ogród wypełniony pięknymi starymi drzewami. Tektonika budynku i jego bryła to swoista rozmowa z otaczającymi koronami drzew. Rytm uskoków powiela niejako naturalną pierzeję drzew i konarów, które kształtują elewację. Skalą budynku nawiązano do kameralnej zabudowy historycznego Górnego Mokotowa. Układ funkcjonalny dostosowano zaś do współczesnych potrzeb budynku apartamentowego. Wzorcem były zabytkowe modernistyczne wille Mokotowa, które często licowane były cegłą. Architektura pełna elegancji 20-lecia międzywojennego ma swoje odzwierciedlenie we współczesnym detalu i materiałach projektowanego budynku.
            <br /><br />
            Elewacja w kolorach szarości ma być tłem dla zieleni parku ale również nawiązaniem do zrealizowanej przed wojną kolonii „szarych domów” zrealizowanych przy ul. Narbutta w 1932 roku. Podobnie jak tam podzielona osiowo elewacja została skomponowana symetrycznie z loggiami balkonowymi z zaakcentowaniem wycofań elewacji z kontrastującym z cegłą materiałem. To właśnie od strony parku zaprojektowano szerokie loggie, które umożliwiają każdemu z mieszkańców obcowanie z naturą w sercu metropolii.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section;
