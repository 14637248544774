
function Section() {
  return (
    <div className="section section-reverse" id="s4">
      <div className="bg-wrap">
        <div className="pic f1 bg" id="p12" />
      </div>

      <div className="text">
        <div className="text-border">
          <h1>PARK SGGW</h1>
          <h2>– wyjątkowe sąsiedztwo</h2>
          <div className="running-text">
            Apartamenty Bruna 7 będą sąsiadują bezpośrednio z wyjątkową zieloną przestrzenią. Park Szkoły Głównej Gospodarstwa Wiejskiego to ponad stuletni ogród o funkcjach naukowych i dydaktycznych o powierzchni 1,6 ha położony między ul. Bruna i aleją Niepodległości. Zlokalizowany po północnej stronie reprezentacyjnych budynków SGGW jest ważnym elementem kompozycji historycznego kampusu uczelni. Park skupiający na swoim terenie rzadką i cenną kolekcję drzew. Rosną tu takie gatunki jak m.in. kasztanowiec zwyczajny, perełkowiec japoński, glediczia trójcierniowa, miłorząb dwuklapowy, klęk amerykański i azalia pontyjska oraz dwa pomniki przyrody: buk zwyczajny i leszczyna turecka. Najstarszy drzewostan pochodzi z XIX w. – okresu funkcjonowania Mokotowskiego Wojennego Pola. Między innymi dlatego zespół przyrodniczo-krajobrazowy - Park SGGW został objęty ochroną i został wpisany do rejestru zabytków nieruchomych województwa mazowieckiego.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section;
