import React, { useState, useEffect } from 'react';
//import LogoBruna from '../pictures/LOGO-BRUNA-7-b.png'




const animationMAX = 1000;


function Section() {
  const [position, setPosition] = useState(-100000);

  useEffect(() => {

    const intervalId = setInterval(() => {

      setPosition((old) => {
        if(old === 100000) return -100000
        else return old + 1
      });

    }, 25);

    return () => {
      clearInterval(intervalId);
    };
  }, []);


  const style = `
    #tlo2 {
      background-position-x: ${-position}px
    }
  `

  return (
    <div
      className="Hero Hero1"
      id='tlo2'
    >
        <style>
          {style}
        </style>
        <span className="hidden">Dobra lokalizacja</span>
        <div>
          <img src='/LOGO-BRUNA-7-b.png' alt="logo inwestycji" width="474px" height="1726px"/>
        </div>
        <span>Mieszkać z widokiem na park</span>
    </div>
  );
}

export default Section;
