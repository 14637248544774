
function Section() {
  return (
    <div className="section" id="s4">
      <div className="column f1">
        <div className="bg-wrap">
          <div className="pic f1 bg" id="p10" />
        </div>

        <div className="bg-wrap">
          <div className="pic f1 bg" id="p11" />
        </div>
      </div>

      <div className="text">
        <div className="text-border">
          <h1>MOKOTÓW</h1>
          <h2>tradycja zobowiązuje</h2>
          <div className="running-text">
            Nazwa Mokotowo pojawia się już 1367 r., jednak historia Mokotowa zaczyna się w końcu XVII w. gdy zaczęto wznosić modne rezydencje zlokalizowane na skarpie mokotowskiej. Jedną z nich była rezydencja Mon Coteau - Moje Wzgórze księżnej Izabeli z Czartoryskich Lubomirskiej. Najważniejszym był jednak rok 1916 Mokotów został włączony w administracyjny obszar Warszawy. Intensywny rozwój dzielnicy nastąpił w dwudziestoleciu międzywojennym. Na terenie obecnego Starego Mokotowa zaczęły powstawać eleganckie wille i kamienice, a wzdłuż szerokiej alei Niepodległości nowoczesne i luksusowo wykończone budynki z windami i garażami podziemnymi.<br/><br/>
            Na terenie obecnego Pola Mokotowskiego miała także powstać reprezentacyjna dzielnica im. Marszałka Józefa Piłsudskiego. Wcześniej planowano wybudowanie na południe od Alej Jerozolimskich rozległej Dzielnicy Nauki. Pomysł ten później zmodyfikowano, a założenie miało mieć charakter również administracyjny i być włączone do dzielnicy Piłsudskiego.<br/><br/>
            Jednym z pierwszych zaplanowanych budynków Dzielnicy Nauki był gmach Instytutu Geologicznego przy ulicy Rakowieckiej 4 oraz zespół budynków Wyższej Szkoły Handlowej (później SGPiS, obecnie SGH). Fasady budynków zaprojektowano od ulicy Batorego, gdyż przed wojną przylegały właśnie do niej. Po wojnie ulicę wytyczono kilkadziesiąt metrów dalej na północ i fasady schowały się obecnie w gąszczu zieleni. Po drugiej stronie alei Niepodległości stanął gmach Szkoły Głównej Gospodarstwa Wiejskiego, przy którym powstał park naukowo-dydaktyczny - bezpośredni sąsiad naszej rezydencji przy ulicy Bruna 7.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section;
