
function Section() {
  return (
    <div className="section section-reverse" id="s3">
      {/* <div className="pictures row"> */}
      <div className="bg-wrap">
        <div className="pic f1 bg" id="p4"/>
      </div>
        {/* <div className="column f1">
          <div className="pic f1 bg" id="p5"/>
          <div className="f1 row">
            <div className="pic f1 bg" id="p6"/>
            <div className="pic f1 bg" id="p7"/>
          </div>
        </div>
      </div> */}

      <div className="text">
        <div className="text-border">
          <h1>UNIKALNE MIEJSCE</h1>
          <h2>na mapie Warszawy</h2>
          <div className="running-text">
            Mokotów to jedna z piękniejszych dzielnic Warszawy oraz jedno z najbardziej poszukiwanych i prestiżowych miejsc do zamieszkania. Budynek Bruna 7 jest zlokalizowany w jego najstarszej części, najbliżej centrum, w bezpośrednim sąsiedztwie starego parku i przedwojennych zabudowań Szkoły Głównej Gospodarstwa Wiejskiego. Tuż obok rozciągają się rozległe zielone tereny Pola Mokotowskiego z jednej strony, oraz kameralne uliczki Starego Mokotowa z drugiej strony. <br /><br />
            Okolica Bruna 7 to jednak nie tylko wszechobecna zieleń i przedwojenna architektura, ale i zapewniające komfort codziennego życia sklepy, szkoły i przedszkola, wyższe uczelnie, kawiarnie i modne restauracje. Doskonała komunikacja i bliskość alei Niepodległości ułatwia codzienne życie, a do stacji metra Pole Mokotowskie oraz przystanków autobusowych i tramwajowych jest tylko 200 m.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section;
