//import LogoBruna from '../pictures/LOGO-BRUNA-7-b.png'

function Section() {
  return (
    <div className="Hero" id='tlo2'>
        <div>
          <img src='/LOGO-BRUNA-7-b.png' alt="logo inwestycji" width="474px" height="1726px"/>
        </div>
    </div>
  );
}

export default Section;
