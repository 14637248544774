
function Section() {
  return (
    <div className="section" id="s2">
      <div className="bg-wrap">
        <div className="pic bg" id="p2"/>
      </div>
      <div className="text">
        <div className="text-border">
          <h1>LUKSUSOWA</h1>
          <h2>rezydencja przy parku</h2>
          <div className="running-text">
            Zapraszamy Państwa do wyjątkowej rezydencji zlokalizowanej na Starym Mokotowie, tuż przy Polu Mokotowskim przy ulicy Giordana Bruna 7. Kameralna inwestycja Bruna 7 to elegancki apartamentowiec klasy premium, którego rangę wyznacza doskonała lokalizacja i wyjątkowa architektura. Projekt zakłada stworzenie kameralnego trzypiętrowego budynku z 33 apartamentami, garażem podziemnym oraz stylową strefą relaksu, na którą składa się fitness, przestrzeń do jogi i pilatesu oraz sala spotkań. Mieszkańcy będą mieli do dyspozycji duże tarasy i balkony, a właściciele apartamentów zlokalizowanych na parterze własne ogródki.<br/><br/>
            Niewątpliwym wyróżnikiem inwestycji Bruna 7 będzie jednak bezpośrednie sąsiedztwo Parku SGGW i roztaczający się widok na zabytkowy starodrzew. Bruna 7 to prestiż i wygoda, które daje połączenie lokalizacji w centrum stolicy ze spokojem, przyrodą i klasą luksusowej architektury.
        </div>
        </div>
      </div>
    </div>
  );
}

export default Section;
