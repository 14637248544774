import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import webp from '../pictures/Rezydencja-Tagore-11-pupup.webp'

function PopUp(props) {
    const [on, set_on] = useState(true);

    const css = `
        body {
            padding-right: 0px!important;
        }
        .popup {
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none;
            margin: 16px;
        }
        .popup-content{
            outline: none;
            position: relative;
        }
        .popup-content:hover{
            filter: brightness(0.9);
            cursor: pointer;
        }
        button.popup-close {
            position: absolute;
            right: 5px;
            top: 5px;
            width: 43px;
            height: 43px;
            cursor: pointer;
            border-radius: 40px;
            border: 0;
            background: none;
        }
        @media only screen and (max-width: 600px) {
            button.popup-close {
                right: unset;
                left: 0;
            }
        }
        button.popup-close:hover{
            background: rgba(0,0,0,0.04);
        }
        button.popup-close > svg {
            width: 40px;
            height: 40px;
            color: black;
            fill: red;
        }
        .alignnone{
            max-width: 90dvw;
            width: 100%;
            object-fit: contain;
            outline: none;
            max-height: 90dvh;
            height: 100%;
        }
        .popup-content:has(.popup-close:hover) {
            filter: brightness(1);
        }
    `


    return (
        <>
            <style>
                {css}
            </style>
            <Modal
                open={on}
                aria-labelledby="Polityka prywatnosci"
                className="popup"
                onClick={()=>{set_on(false)}}
            >
                <div
                    className='popup-content'
                >
                    <button
                        className="popup-close"
                        onClick={()=>{set_on(false)}}
                    >
                        <svg viewBox="0 0 24 24" >
                            <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                        </svg>
                    </button>
                    <img
                        onClick={()=>{ window.open('https://www.vinci-immobilier.pl/mieszkania-i-lokale/rezydencja-tagore/tagore-a-1-11/', '_blank').focus();}}
                        fetchpriority="high"
                        decoding="async"
                        src={webp}
                        alt=""
                        width="940"
                        height="788"
                        class="alignnone"
                    />
                </div>
            </Modal>
        </>

    );
}

export default PopUp;
